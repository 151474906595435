import langjson from "../helpers/translations.json"

export default function translate (lang, string, vars){
if(!lang){
    lang = "LV"
}

if(langjson[string] === undefined || langjson[string][lang] === undefined){
    return string
}

let text = langjson[string][lang].includes("{0}")

if(text){
return langjson[string][lang].replace("{0}", vars)
}

return langjson[string][lang]
}

